import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sidebarActive } from '@Lib/slices/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ChevronDown } from 'react-feather';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  NavLink,
} from 'reactstrap';

function MenuItemHasChild({ item, handleClickItem }) {
  const accessPermisions = useSelector((state) => state.user.accessPermisions);
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();
  useEffect(() => {
    const childMenu = item.child.find((childRoute) => childRoute.path === routeMatch.path);
    if (childMenu) {
      dispatch(sidebarActive(item.id));
    }
  }, []);

  const validAccess = (menu) => {
    const accessName = menu.accessName;
    if (!accessName) {
      return true;
    }
    if (accessPermisions && accessPermisions[`${menu.accessName}`]) {
      return true;
    }
    return false;
  };

  return item.isSubMenu ? (
    <UncontrolledDropdown tag="li" className="nav-item">
      <DropdownToggle className={`btn ${item.isActive ? 'active' : ''}`} color="none">
        {item.name}
        {item.isSubMenu && <ChevronDown size={18} />}
      </DropdownToggle>
      <DropdownMenu className="">
        {item.child.length > 0 &&
          item.child.map(
            (menu) =>
              !menu.hidden &&
              validAccess(menu) && (
                <DropdownItem
                  onClick={() => handleClickItem(menu)}
                  tag="a"
                  key={menu.id}
                  className="d-flex align-items-center"
                >
                  {menu.image != null && (
                    <img src={menu.image} alt={menu.name} width="24" height="24" />
                  )}
                  <span className="ml-25">{menu.name}</span>
                </DropdownItem>
              ),
          )}
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <li className="nav-item">
      <NavLink onClick={() => handleClickItem(item)} className={`${item.isActive ? 'active' : ''}`}>
        {item.name}
      </NavLink>
    </li>
  );
}

MenuItemHasChild.propTypes = {
  item: PropTypes.object,
  handleClickItem: PropTypes.func,
};

export default MenuItemHasChild;
