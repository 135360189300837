import React from 'react';
import { toast } from 'react-toastify';
import { XOctagon, Check } from 'react-feather';
import parse from 'html-react-parser';

export function toastSuccess(message) {
  toast(
    <div className="bg-white">
      <div className="toastify-header">
        <div className="title-wrapper d-flex align-items-center">
          <Check color="#28c76f" />
          <p className="toast-title font-weight-bolder text-success mb-0 ml-25">Success!</p>
        </div>
      </div>
      <div className="toastify-body mt-1">
        <span role="img" aria-label="toast-text text-info">
          {message}
        </span>
      </div>
    </div>,
  );
}

const getMessageText = (error) => {
  if (typeof error === 'string') {
    return error;
  }
  const { response } = error;
  const { status, data } = response;
  const { errors, message } = data;
  if (errors) {
    let msg = '';
    Object.keys(errors).map((key) => {
      errors[key].map((err) => {
        msg += `\n${err}`;
      });
    });
    return msg;
  }
  return `${status} ${message}`;
};

export function toastError(error) {
  const message = getMessageText(error);
  toast(
    <div className="bg-white">
      <div className="toastify-header">
        <div className="title-wrapper d-flex align-items-center">
          <XOctagon color="#ea5455" size={18} />
          <p className="toast-title font-weight-bolder text-danger mb-0 ml-25">Error!</p>
        </div>
      </div>
      <div className="toastify-body mt-1">
        <span role="img" aria-label="toast-text text-info">
          {message}
        </span>
      </div>
    </div>,
  );
}
