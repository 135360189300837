import React, { memo } from 'react';
import PrivateLayout from '@Components/Layouts/PrivateLayout';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AuthenWrapper from '@Components/AuthenWrapper';
import { useSelector } from 'react-redux';
import PageErrorPermission from '../../views/PageErrorPermission';

function PrivateRoutes(props) {
  const { component: YourComponent, accessName, accessChild, ...remainProps } = props;
  const accessPermissions = useSelector((state) => state.user.accessPermisions);
  return (
    <AuthenWrapper>
      <Route
        {...remainProps}
        render={(routeProps) => {
          if (accessPermissions) {
            let checkAccess = false;
            let checkAccessChild = true;
            if (accessPermissions[accessName] || !accessName) {
              checkAccess = true;
            }
            if (accessChild && !accessPermissions[accessChild]) {
              checkAccessChild = false;
            }
            if (checkAccess && checkAccessChild) {
              return (
                <PrivateLayout>
                  <YourComponent {...routeProps} />
                </PrivateLayout>
              );
            }
            return <PageErrorPermission />;
          }
          return '';
        }}
      />
    </AuthenWrapper>
  );
}

PrivateRoutes.propTypes = {
  component: PropTypes.object,
  accessName: PropTypes.string,
  accessChild: PropTypes.string,
};

export default memo(PrivateRoutes);
