import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { hidePopupRule } from '@Lib/slices/uiSlice';

function ModalRule() {
  const dispatch = useDispatch();
  const isShowPopupRule = useSelector((state) => state.ui.isShowPopupRule);
  return (
    <Modal
      centered
      isOpen={isShowPopupRule}
      toggle={() => dispatch(hidePopupRule())}
      style={{ maxWidth: '1000px' }}
    >
      <img src="/images/rule-vuvu.jpg" alt="rule" />
    </Modal>
  );
}

ModalRule.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalRule;
