/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import axiosService from '@Lib/axiosService';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrent, getAccessPermissions } from '@Lib/slices/userSlice';
import { getAllStatus } from '@Lib/slices/orderSlice';
import { customerGroups, customerLevels } from '@Lib/slices/customerSlice';
import { showGlobalLoading, hideGlobalLoading } from '@Lib/slices/uiSlice';
import { privateConstant } from '../../routers/private/contant';

function AuthenWrapper(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { state, pathname } = location;
  let login = '';
  if (state) {
    login = state.login;
  }
  const history = useHistory();
  const info = useSelector((state) => state.user.info);
  const token = localStorage.getItem(process.env.TOKEN);
  const projectActive = useSelector((state) => state.user.projectActive);
  if (token) {
    axiosService.setHeader('Authorization', `Bearer ${token}`);
  } else {
    history.push('/login');
  }
  const isLoading = useSelector((state) => state.user.isLoading);
  const accessPermisions = useSelector((state) => state.user.accessPermisions);

  useEffect(() => {
    if (token && !info) {
      authenNeedExecute();
    }
  }, [token, info]);

  useEffect(() => {
    if (login === 'Login') {
      authenNeedExecute();
    }
  }, [login]);

  useEffect(() => {
    if (login === 'Login' && accessPermisions) {
      let name = '';
      for (const [key, value] of Object.entries(accessPermisions)) {
        if (value) {
          name = key;
          break;
        }
      }
      const router = privateConstant.find((item) => item.accessName === name);
      history.push(router.path);
    }
  }, [login, accessPermisions]);

  const authenNeedExecute = () => {
    dispatch(getCurrent());
    dispatch(getAllStatus());
    dispatch(customerGroups());
    dispatch(customerLevels());
  };

  useEffect(() => {
    if (projectActive && !accessPermisions) {
      const { id: projectId } = projectActive;
      dispatch(getAccessPermissions({ projectId }));
    }
  }, [projectActive, accessPermisions]);

  useEffect(() => {
    if (isLoading) {
      dispatch(showGlobalLoading());
    } else {
      dispatch(hideGlobalLoading());
    }
  }, [isLoading]);

  if (info) {
    return children;
  }
  return '';
}

AuthenWrapper.propTypes = {};

export default AuthenWrapper;
