import GlobalLoading from '@Components/GlobalLoading';
import React from 'react';
import { Provider } from 'react-redux';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routers';
import store from './store';

const queryClient = new QueryClient();

export default function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Routes />
        <GlobalLoading />
        <ToastContainer />
      </QueryClientProvider>
    </Provider>
  );
}
