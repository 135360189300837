import axiosService from '@Lib/axiosService';

const url = `${process.env.URL}`;

export const getCustomers = (params) => {
  const { projectId, tabParam, ...restParams } = params;
  return axiosService
    .get(`${url}/projects/${projectId}/customers${tabParam ?? ''}`, restParams)
    .then((res) => res.data);
};

export const addCustomer = (params) => {
  const { projectId, ...restParams } = params;
  return axiosService
    .post(`${url}/projects/${projectId}/customers`, restParams)
    .then((res) => res.data);
};

export const getCustomerDetail = (params) => {
  const { projectId, customerId } = params;
  return axiosService
    .get(`${url}/projects/${projectId}/customers/${customerId}`)
    .then((res) => res.data.data);
};

export const editCustomer = (params) => {
  const { projectId, customerId, ...restParams } = params;
  return axiosService
    .put(`${url}/projects/${projectId}/customers/${customerId}`, restParams)
    .then((res) => res.data);
};

export const getCustomerLevels = () => {
  return axiosService.get(`${url}/customerLevels`).then((res) => res.data);
};

export const getCustomerGroups = () => {
  return axiosService.get(`${url}/customerGroups`).then((res) => res.data);
};

export const deleteCustomer = ({ projectId, customerId }) => {
  return axiosService
    .delete(`${url}/projects/${projectId}/customers/${customerId}`)
    .then((res) => res.data);
};
