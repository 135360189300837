export const getInputErrorsObject = (name, errors) => {
  const propName = name.substr(0, name.indexOf('.'));
  const childrenPropsName = name.substr(name.indexOf('.') + 1, name.length);
  if (!(propName in errors)) {
    if (childrenPropsName in errors) {
      return errors[childrenPropsName];
    }
    return;
  }

  return getInputErrorsObject(childrenPropsName, errors[propName]);
};
