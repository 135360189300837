import axiosService from '@Lib/axiosService';

const url = `${process.env.URL}`;

export const getOrders = (params) => {
  const { projectId, ...restParams } = params;
  return axiosService
    .get(`${url}/projects/${projectId}/orders`, restParams)
    .then((res) => res.data);
};

export const getOrderDetail = (params) => {
  const { projectId, orderId } = params;
  return axiosService
    .get(`${url}/projects/${projectId}/orders/${orderId}`)
    .then((res) => res.data.data);
};

export const editOrder = (params) => {
  const { projectId, orderId, data } = params;
  return axiosService
    .put(`${url}/projects/${projectId}/orders/${orderId}`, data)
    .then((res) => res.data.data);
};

export const deleteOrder = (params) => {
  const { projectId, data } = params;
  return axiosService.delete(`${url}/projects/${projectId}/orders`, data).then((res) => res.data);
};

export const addNewOrder = (params) => {
  const { projectId, data } = params;
  return axiosService
    .post(`${url}/projects/${projectId}/orders`, data)
    .then((res) => res.data.data);
};

export const getStatus = async ({ projectId }) => {
  return axiosService
    .get(`${url}/projects/${projectId}/orders/statusesCount`)
    .then((res) => res.data);
};

export const getTags = async () => {
  return axiosService.get(`${url}/tags`).then((res) => res.data.data);
};

export const getAllOrderStatus = () => {
  return axiosService.get(`${url}/statuses`).then((res) => res.data);
};

export const editStatus = (params) => {
  const { projectId, orderId, statusId: status_id, pivotId: pivot_id } = params;
  return axiosService
    .put(`${url}/projects/${projectId}/orders/${orderId}/status`, {
      status_id,
      pivot_id,
    })
    .then((res) => res.data);
};

export const getComments = (params) => {
  const { projectId, orderId } = params;
  return axiosService
    .get(`${url}/projects/${projectId}/orders/${orderId}/comments`)
    .then((res) => res.data.data);
};

export const addNewComment = (params) => {
  const { projectId, orderId, body } = params;
  return axiosService
    .post(`${url}/projects/${projectId}/orders/${orderId}/comments`, body)
    .then((res) => res.data.data);
};

export const editComment = (params) => {
  const { projectId, orderId, commentId, body } = params;
  return axiosService
    .put(`${url}/projects/${projectId}/orders/${orderId}/comments/${commentId}`, body)
    .then((res) => res.data.data);
};

export const getActivities = (params) => {
  const { projectId, orderId, ...restParams } = params;
  return axiosService
    .get(`${url}/projects/${projectId}/orders/${orderId}/activities`, restParams)
    .then((res) => res.data);
};

export const makeKeySearch = (params) => {
  const { projectId, orderId } = params;
  return axiosService
    .post(`${url}/projects/${projectId}/orders/${orderId}/callHistories`)
    .then((res) => res.data);
};

export const getCallHistory = (params) => {
  const { projectId, orderId } = params;
  return axiosService
    .get(`${url}/projects/${projectId}/orders/${orderId}/callHistories`)
    .then((res) => res.data);
};

export const updateShippingCompany = (params) => {
  const { projectId, orderId, body } = params;
  return axiosService
    .put(`${url}/projects/${projectId}/orders/${orderId}/shippingCompany`, body)
    .then((res) => res.data);
};
