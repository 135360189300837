import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

function PageNotFound(props) {
  const history = useHistory();
  return (
    <div>
      <div className="d-flex justify-content-center pt-2">
        <img src="/images/logo/logo-vuvu-02.png" width="260" alt="logo" />
      </div>
      <div className="container">
        <div className="content-public-wrapper">
          <div className="d-flex justify-content-center">
            <div>
              <img src="/images/not-found.png" alt="page not found" />
              <p className="text-center pt-3 pb-1">Không tìm thấy trang bạn yêu cầu.</p>
              <div className="w-100 text-center">
                <Button
                  color="primary"
                  className="btn btn-primary py-15p px-40p"
                  onClick={() => history.push('/dashboard')}
                >
                  Về trang chủ
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PageNotFound.propTypes = {};

export default PageNotFound;
