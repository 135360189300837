import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavItem, Button, Container, NavLink } from 'reactstrap';
import { Menu } from 'react-feather';
import UserDropdown from './UserDropdown';
import NotificationDropDown from './NotificationDropDown';
import ProjectsDropdown from './ProjectsDropdown';
import HeaderSearch from './HeaderSearch';

function Header({ setMenuVisibility, windowWidth }) {
  return (
    <Navbar
      className={`header-navbar navbar-fixed align-items-center navbar-brand-center bg-white navbar-light navbar-expand ${
        windowWidth < 1200 ? 'fixed-top' : ''
      }`}
    >
      <div className="navbar-container d-flex content align-items-center justify-content-between">
        <NavLink className="d-block d-lg-none" onClick={setMenuVisibility}>
          <Menu />
        </NavLink>
        <div className="brand-logo d-xl-block d-none">
          <img src="/images/logo/logo-vuvu-02.png" className="logo-vuvu" alt="logo" />
        </div>
        <HeaderSearch />
        <UserDropdown />
        {/* <ul className="nav navbar-nav align-items-center ml-auto flex-1 flex-nowrap">
          <HeaderSearch />
           <ProjectsDropdown />
           <NotificationDropDown />
          <UserDropdown />
        </ul> */}
      </div>
    </Navbar>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  setMenuVisibility: PropTypes.func,
  windowWidth: PropTypes.number,
};

export default Header;
