/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { showGlobalLoading, hideGlobalLoading } from '@Lib/slices/uiSlice';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { createBrowserHistory } from 'history';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  async componentDidCatch(error, errorInfo) {
    this.props.hide();
    console.log(error.stack);
    // sendMessageToSlack(error.stack);
    this.setState({ errorInfo });
  }

  render() {
    const { name, url } = this.props;
    const { hasError, errorInfo } = this.state;

    const handleSubmit = () => {
      const history = createBrowserHistory();
      history.push('/dashboard');
      this.setState({
        hasError: false,
      });
    };

    if (hasError) {
      return (
        <div>
          <div className="d-flex justify-content-center pt-2">
            <img src="/images/logo/logo-vuvu-02.png" width="260" alt="logo" />
          </div>
          <div className="container">
            <div className="content-public-wrapper">
              <div className="d-flex justify-content-center">
                <div>
                  <img src="/images/error.png" alt="page not found" />
                  <p className="text-center pt-3 pb-1">
                    Có lỗi xảy ra. Vui lòng thử tải lại trang.
                  </p>
                  <div className="w-100 text-center">
                    <a color="primary" className="btn btn-primary py-15p px-40p" href="/dashboard">
                      Về trang chủ
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  name: PropTypes.string,
  url: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    hide: () => dispatch(hideGlobalLoading()),
    show: () => dispatch(showGlobalLoading()),
  };
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
