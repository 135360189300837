/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropsType from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import ModalRule from '@Components/ModalRule';
import GlobalLoading from '@Components/GlobalLoading';
import Header from './Header';
import HorizontalMenu from './HorizontalMenu';

export default function PrivateLayout(props) {
  const { children } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuVisibility, setMenuVisibility] = useState(false);

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('resize', handleWindowWidth);
    }
  }, [windowWidth]);

  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  const location = useLocation();

  useEffect(() => {
    if (menuVisibility && windowWidth < 1200) {
      setMenuVisibility(false);
    }
  }, [location]);

  return (
    <div
      className={classnames('horizontal-layout horizontal-menu navbar-floating footer-static', {
        'menu-hide': !menuVisibility && windowWidth < 1200,
        'menu-open': menuVisibility && windowWidth < 1200,
      })}
    >
      <Header
        setMenuVisibility={() => setMenuVisibility(!menuVisibility)}
        windowWidth={windowWidth}
      />
      <HorizontalMenu setMenuVisibility={() => setMenuVisibility(!menuVisibility)} />
      <div
        role="button"
        className={classnames('sidenav-overlay', {
          show: menuVisibility,
        })}
        onClick={() => setMenuVisibility(false)}
      />
      <div className="app-content content">
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <div className="">{children}</div>
      </div>
      <GlobalLoading />
      <ModalRule />
    </div>
  );
}

PrivateLayout.propTypes = {
  children: PropsType.object,
};
