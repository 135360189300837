/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
import { INVENTORY_CHECK_RECEIPTS_TYPES, SHIPPING_COMPANY } from '@Lib/constant';

export const getURLParameters = (url) => {
  return (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a, v) => ((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a),
    {},
  );
};
/**
 * Return day and one digital month format dd/M
 * @param {number} timeOrigin
 */
export const getDateAndOneDigitalMonth = (timeOrigin) => {
  const date = new Date(timeOrigin);
  return `${date.getDate()}/${date.getMonth() + 1}`;
};

export const parsePositiveNumber = (expression) => {
  if (expression) {
    return expression.replace(/[^0-9]+/g, '');
  }
  return '';
};

export const parseNumber = (expression) => {
  if (expression) {
    return expression.replace(/[^0-9\\-\\.]+/g, '');
  }
  return '';
};

export const parsePrice = (expression) => {
  if (expression) {
    return expression.replace(/[^0-9-]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
};

/**
 * return string datetime format hh:mm dd/mm
 * @param {string} datetime IOS string datetime
 */

export const formatTimeDataTable = (datetime) => {
  const date = _formatDate(datetime);
  const time = _formatTime(datetime);
  if (date && date.length === 3 && time && time.length === 2) {
    return `${time[0]}:${time[1]} ${date[0]}/${date[1]}`;
  }
  return '';
};

/**
 * Return array [day, month, year]
 * @param {string} dateRaw datetime type ISO
 */
const _formatDate = (dateRaw) => {
  if (!dateRaw) return null;
  const d = new Date(dateRaw);
  const date = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return [date, month, year];
};

/**
 * Return array [hours, minutes]
 * @param {string} dateRaw  datetime type ISO
 */
const _formatTime = (dateRaw) => {
  const d = new Date(dateRaw);
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes() + 1).padStart(2, '0');
  return [hours, minutes];
};

export const getFormatTime = (dateRaw) => {
  const objTime = _formatTime(dateRaw);
  return `${objTime[0]}:${objTime[1]}`;
};
/**
 * return phone number hide 3 last character
 * @param {string} phone
 */
export const hidePhoneNumber = (phone, isAdmin = false) => {
  if (isAdmin) {
    return phone;
  }
  return `${phone?.substring(0, phone.length - 3)}xxx`;
};

export const formatVND = (price) => {
  price = Math.trunc(price);
  if (price) {
    return `${price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')} đ`;
  }
  return '0 đ';
};

/**
 * Returns array [day, month, year]
 * @param {object} d object time
 */
const _formatDateFromObject = (d) => {
  const date = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return [date, month, year];
};

/**
 * Return date format dd/m/yyyy
 * @param {string | object} dateRaw datetime type ISO
 */
export const timeShow = (dateRaw) => {
  let date;
  if (!dateRaw) {
    return '';
  }
  if (typeof dateRaw === 'string') {
    date = _formatDate(dateRaw);
  } else if (typeof dateRaw === 'object') {
    date = _formatDateFromObject(dateRaw);
  }
  if (date && date.length === 3) {
    return `${date[1]}/${date[0]}/${date[2]}`;
  }
  return '';
};

/**
 * Return date format dd-mm-yyyy
 * @param {object} dateRaw object time
 */
export const timeForAPI = (dateRaw) => {
  const date = _formatDate(dateRaw);
  if (date && date.length === 3) {
    return `${date[0]}-${date[1]}-${date[2]}`;
  }
  return '';
};

/**
 * Return date format dd/mm
 * @param {object} dateRaw object time
 */
export const timeForLineChart = (dateRaw) => {
  const date = _formatDate(dateRaw);
  if (date && date.length === 3) {
    return `${date[0]}/${date[1]}`;
  }
  return '';
};

export const findGetParameter = (parameterName) => {
  let result = null;
  let tmp = [];
  const params = window.location.search.substr(1).split('&');
  params.forEach((item) => {
    tmp = item.split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  });
  return result;
};

export const getLastDateOfWeek = () => {
  const date = new Date();
  const lastday = date.getDate() - (date.getDay() - 1) + 6;
  return new Date(date.setDate(lastday));
};

export const getFirstDateOfWeek = () => {
  const date = new Date();
  const fistday = date.getDate() - (date.getDay() - 1);
  return new Date(date.setDate(fistday));
};

export const getLastDateOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getFirstDateOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const _formatYYMMDD = (dateRaw) => {
  const date = _formatDate(dateRaw);
  if (date && date.length === 3) {
    return `${date[2]}-${date[1]}-${date[0]}`;
  }
  return '';
};

export const getDates = (startDate, endDate) => {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(_formatYYMMDD(currentDate));
    currentDate = new Date(currentDate).fp_incr(1);
  }
  return dateArray;
};

export const formatDateTimeForShow = (datetime) => {
  const date = _formatDate(datetime);
  const time = _formatTime(datetime);
  if (date && date.length === 3 && time && time.length === 2) {
    return `${date[0]}/${date[1]}/${date[2]} ${time[0]}:${time[1]}`;
  }
  return '';
};

export const parseNoneVietnamese = (expression) => {
  let slug = expression.toLowerCase();
  // xóa dấu
  slug = slug.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
  slug = slug.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
  slug = slug.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
  slug = slug.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
  slug = slug.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
  slug = slug.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
  slug = slug.replace(/(đ)/g, 'd');

  // Xóa ký tự đặc biệt
  slug = slug.replace(/([^0-9a-z-\s])/g, '');

  // Xóa ký tự - liên tiếp
  slug = slug.replace(/-+/g, '-');

  // xóa phần dự - ở đầu
  slug = slug.replace(/^-+/g, '');

  // xóa phần dư - ở cuối
  slug = slug.replace(/-+$/g, '');
  // In Hoa Phan tu
  // slug = slug.toUpperCase();
  // return
  return slug;
};

export const formatDateForFlatPickr = (dateRaw) => {
  const date = _formatDate(dateRaw);
  if (date && date.length === 3 && !date.includes(NaN)) {
    return `${date[0]}-${date[1]}-${date[2]}`;
  }
  return null;
};

export const convertArrayToDataTable = (items) => {
  return {
    data: items,
    meta: null,
  };
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getSumOfItemsInArray = (arr) => {
  return arr?.reduce((a, b) => a + b, 0);
};

export const getShippingCompanyName = (shippingCompanyId) => {
  const shippingCompany = SHIPPING_COMPANY.find((item) => item.value === shippingCompanyId);
  return shippingCompany ? shippingCompany.name : '';
};

export const getInventoryCheckReceiptType = (type) => {
  const receipt = INVENTORY_CHECK_RECEIPTS_TYPES.find((item) => item.value === type);
  return receipt?.label ?? '';
};
export const checkTextSearch = ({ productName, keySearch }) => {
  if (!productName || !keySearch) {
    return true;
  }
  const formatProductName = parseNoneVietnamese(productName);
  const parseKeySearch = parseNoneVietnamese(keySearch);
  const check = formatProductName.indexOf(parseKeySearch);
  return check < 0 ? false : true;
};
