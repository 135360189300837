import axiosService from '@Lib/axiosService';

const url = `${process.env.URL}`;

export const getInfoUser = ({ id }) => {
  return axiosService.get(`${url}/users/${id}`).then((res) => res.data);
};

export const getCurrentUser = () => {
  return axiosService.get(`${url}/users/current`).then((res) => res.data);
};

export const getSales = ({ projectId }) => {
  return axiosService.get(`${url}/projects/${projectId}/users/sales`).then((res) => res.data.data);
};

export const getUsers = (query) => {
  const { projectId, ...restQuery } = query;
  return axiosService.get(`${url}/projects/${projectId}/users`, restQuery).then((res) => res.data);
};

export const createUser = (body) => {
  return axiosService.post(`${url}/projects/0/users`, body).then((res) => res.data);
};

export const editUser = (body) => {
  const { userId, ...restBody } = body;
  return axiosService.put(`${url}/projects/0/users/${userId}`, restBody).then((res) => res.data);
};

export const deleteUser = (body) => {
  const { projectId, userId } = body;
  return axiosService
    .delete(`${url}/projects/${projectId}/users/${userId}`)
    .then((res) => res.data);
};

export const massDeleteUsers = (body) => {
  const { projectId, ...restBody } = body;
  return axiosService
    .post(`${url}/projects/${projectId}/users/massDelete`, restBody)
    .then((res) => res.data);
};

export const massLockUsers = (body) => {
  const { projectId, ...restBody } = body;
  return axiosService
    .post(`${url}/projects/${projectId}/users/massLock`, restBody)
    .then((res) => res.data);
};

export const allUser = () => {
  return axiosService.get(`${url}/users`).then((res) => res.data);
};

export const changePassword = (body) => {
  return axiosService.post(`${url}/users/current/changePassword`, body).then((res) => res.data);
};
