import shortid from 'shortid';
import { lazy } from 'react';

export const publicConstant = [
  {
    id: shortid.generate(),
    name: 'Login',
    path: '/login',
    component: lazy(() => import('../../views/Login')),
    exact: true,
  },
];
