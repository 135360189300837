export const groupInventoryBuy = () => {
  return [
    {
      name: 'Yêu cầu đặt hàng',
      id: 5,
      icon: '/images/order-icon/order-request.svg',
      iconActive: '/images/order-icon/order-request-active.svg',
      color: '#EB5757',
      childId: [],
      child: [],
    },
    {
      id: 6,
      name: 'Đã đặt hàng',
      icon: '/images/order-icon/ordering.svg',
      iconActive: '/images/order-icon/ordering-active.svg',
      color: '#4CAABE',
      childId: [],
      child: [],
    },
    {
      id: 7,
      name: 'Đặt hàng thành công',
      icon: '/images/order-icon/ordered.svg',
      iconActive: '/images/order-icon/ordered-active.svg',
      color: '#52A49A',
      childId: [],
      child: [],
    },
    {
      id: 18,
      name: 'Hết hàng',
      icon: '/images/order-icon/order-request.svg',
      iconActive: '/images/order-icon/order-request-active.svg',
      color: '#EB5757',
      childId: [],
      child: [],
    },
  ];
};
