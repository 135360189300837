/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { privateConstant } from '../../routers/private/contant';

function PageErrorPermission(props) {
  const history = useHistory();
  const accessPermisions = useSelector((state) => state.user.accessPermisions);

  const backToHomePage = () => {
    let name = '';
    for (const [key, value] of Object.entries(accessPermisions)) {
      if (value) {
        name = key;
        break;
      }
    }
    const router = privateConstant.find((item) => item.accessName === name);
    history.push(router.path);
  };
  return (
    <div>
      <div className="d-flex justify-content-center pt-2">
        <img src="/images/logo/logo-vuvu-02.png" width="260" alt="logo" />
      </div>
      <div className="container">
        <div className="content-public-wrapper">
          <div className="d-flex justify-content-center">
            <div>
              <img src="/images/not-permission.png" alt="not permission" />
              <p className="text-center pt-3 pb-1">Bạn không có quyền truy cập trang này.</p>
              <div className="w-100 text-center">
                <Button
                  color="primary"
                  className="btn btn-primary py-15p px-40p"
                  onClick={() => backToHomePage()}
                >
                  Về trang chủ
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PageErrorPermission.propTypes = {};

export default PageErrorPermission;
