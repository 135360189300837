import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

function GlobalLoading() {
  const isGlobalLoading = useSelector((state) => state.ui.isGlobalLoading);
  return (
    isGlobalLoading && (
      <div className="loading-box">
        <div className="spinner-list">
          <Spinner type="grow" size="sm" color="primary" />
          <Spinner type="grow" size="sm" color="secondary" />
          <Spinner type="grow" size="sm" color="success" />
          <Spinner type="grow" size="sm" color="danger" />
          <Spinner type="grow" size="sm" color="warning" />
        </div>
      </div>
    )
  );
}

GlobalLoading.propTypes = {};

export default GlobalLoading;
