import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { setProjectActive } from '@Lib/slices/userSlice';

function ProjectsDropdown() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.user.projects);
  const projectActive = useSelector((state) => state.user.projectActive);
  return (
    <UncontrolledDropdown tag="li" className="nav-item mr-1 dropdown-projects d-none d-sm-block">
      {projectActive && (
        <DropdownToggle>
          <div className="d-flex justify-content-between align-items-center">
            <span>{projectActive.name}</span>
            <ChevronDown />
          </div>
        </DropdownToggle>
      )}

      <DropdownMenu className="w-100">
        {projects.length > 0 &&
          projects.map((project) => (
            <DropdownItem
              onClick={() => dispatch(setProjectActive(project))}
              tag="a"
              key={project.id}
            >
              {project.name}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

ProjectsDropdown.propTypes = {};

export default ProjectsDropdown;
