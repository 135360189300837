import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentUser } from '@Apis/user';
import { accessPermissions } from '@Apis/projects/permissions';

export const getCurrent = createAsyncThunk('user/getInfo', async (thunkApi) => {
  try {
    const { data } = await getCurrentUser();
    return data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

export const getAccessPermissions = createAsyncThunk(
  'user/permissions',
  async (params, thunkApi) => {
    try {
      const data = await accessPermissions(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue({ error: error.message });
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    info: null,
    projects: [],
    projectActive: null,
    isLoading: false,
    accessPermisions: null,
  },
  reducers: {
    setProjectActive: (state, action) => {
      const { projectActive } = state;
      const project = action.payload;
      if (project.id !== projectActive.id) {
        localStorage.setItem(process.env.PROJECT, project.id);
        state.projectActive = { ...project };
      }
    },
    removeAccessPermisions: (state) => {
      state.accessPermisions = null;
    },
  },
  extraReducers: {
    [getCurrent.pending]: (state) => {
      state.isLoading = true;
    },
    [getCurrent.fulfilled]: (state, action) => {
      const { projects, ...info } = action.payload;
      const projectActiveId = localStorage.getItem(process.env.PROJECT);
      let projectActive;
      if (!projectActiveId) {
        projectActive = { id: 0, name: 'Tất cả dự án' };
      } else {
        projectActive = projects.find((project) => project.id === parseInt(projectActiveId));
        if (!projectActive) {
          projectActive = { id: 0, name: 'Tất cả dự án' };
        }
      }
      if (projectActive) {
        localStorage.setItem(process.env.PROJECT, projectActive.id);
      }
      state.info = info;
      state.projects = projects;
      state.projectActive = { ...projectActive };
      state.isLoading = false;
    },
    [getAccessPermissions.fulfilled]: (state, action) => {
      const data = action.payload;
      state.accessPermisions = data;
    },
  },
});

const { reducer } = userSlice;
export const selectUser = createSelector(
  (state) => ({
    info: state.user.info,
    projects: state.user.projects,
    projectActive: state.user.projectActive,
    accessPermisions: state.user.accessPermisions,
  }),
  (state) => state,
);

export const { setProjectActive, removeAccessPermisions } = userSlice.actions;

export default reducer;
