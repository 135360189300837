import React, { useState } from 'react';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import PropsType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { LogOut, Key } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { removeAccessPermisions } from '@Lib/slices/userSlice';
import { sidebarActive } from '@Lib/slices/uiSlice';
import ChangePasswordModal from './ChangePasswordModal';

export default function UserDropdown() {
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const toggleChangePasswordModal = () => setChangePasswordModal((prevState) => !prevState);

  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.removeItem(process.env.TOKEN);
    dispatch(removeAccessPermisions());
    dispatch(sidebarActive(null));
    history.push('/login');
  };
  const userInfo = useSelector((state) => state.user.info);

  return (
    <>
      <UncontrolledDropdown tag="div" className="dropdown-user nav-item">
        {userInfo && (
          <DropdownToggle
            className="nav-link dropdown-user-link"
            onClick={(e) => e.preventDefault()}
            nav
          >
            <div className="d-flex align-items-center">
              <div className="avatar-header">
                <span>{userInfo?.name.substring(0, 2).toUpperCase()}</span>
              </div>
              <div className="user-info d-none d-md-block">
                <p className="user-name font-weight-bolder mb-0">{userInfo.name}</p>
                {/* <p className="user-status mb-0">Quản trị viên</p> */}
              </div>
            </div>
          </DropdownToggle>
        )}
        <DropdownMenu right>
          {/* <DropdownItem className="w-100">
          <Users size={14} className="mr-75" />
          <span className="align-middle">Nhân viên</span>
        </DropdownItem> */}
          <DropdownItem className="w-100" onClick={() => toggleChangePasswordModal()}>
            <Key size={14} className="mr-75" />
            <span className="align-middle">Đổi mật khẩu</span>
          </DropdownItem>
          <DropdownItem className="w-100" onClick={() => handleLogout()}>
            <LogOut size={14} className="mr-75" />
            <span className="align-middle text-danger">Đăng xuất</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <ChangePasswordModal isOpen={changePasswordModal} toggle={toggleChangePasswordModal} />
    </>
  );
}

UserDropdown.propTypes = {
  user: PropsType.object,
};
