import React, { memo } from 'react';
import PublicLayout from '@Components/Layouts/PublicLayout';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

function PublicRoutes(props) {
  const { component: YourComponent, ...remainProps } = props;
  return (
    <Route
      {...remainProps}
      render={(routeProps) => {
        return (
          <PublicLayout>
            <YourComponent {...routeProps} />
          </PublicLayout>
        );
      }}
    />
  );
}

PublicRoutes.propTypes = {
  component: PropTypes.object,
};

export default memo(PublicRoutes);
