import React, { useState, useEffect } from 'react';
import { InputGroup, InputGroupAddon, Input, InputGroupText } from 'reactstrap';
import { Search } from 'react-feather';
import { findGetParameter } from '@Utils/helpers';
import { useHistory } from 'react-router-dom';

function HeaderSearch() {
  const history = useHistory();
  const s = findGetParameter('s');
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    if (s) {
      setSearchKey(s);
    }
  }, [s]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchKey) {
      history.push(`/search?s=${searchKey}`);
    }
  };
  return (
    <div className="nav-item mr-1 header-search">
      <form onSubmit={handleSubmit}>
        <InputGroup className="input-group-merge" type="search" name="Search">
          <Input
            className=""
            placeholder="Tìm kiếm tên, mã, sđt khách hàng, mã đơn, thông tin sản phẩm"
            name="s"
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            type="search"
          />
          <InputGroupAddon addonType="append">
            <InputGroupText className="cursor-pointer">
              <Search color="#0062FF" size={18} onClick={handleSubmit} />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </form>
    </div>
  );
}

HeaderSearch.propTypes = {};

export default HeaderSearch;
