import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { sidebarActive } from '@Lib/slices/uiSlice';

function MenuItem({ item, handleClickItem }) {
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (routeMatch.path === item.path) {
      dispatch(sidebarActive(item.id));
    }
  }, []);

  return (
    <li className="nav-item">
      <NavLink onClick={() => handleClickItem(item)} className={`${item.isActive ? 'active' : ''}`}>
        {item.name}
      </NavLink>
    </li>
  );
}

MenuItem.propTypes = {
  item: PropTypes.object,
  handleClickItem: PropTypes.func,
};

export default MenuItem;
