import { createSlice, createSelector } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isOpenPopUpAdvisory: false,
    isGlobalLoading: false,
    sidebar: [],
    sidebarActive: null,
    isShowPopupRule: false,
  },
  reducers: {
    showGlobalLoading: (state) => {
      state.isGlobalLoading = true;
    },
    hideGlobalLoading: (state) => {
      state.isGlobalLoading = false;
    },
    setSideBar: (state, action) => {
      const sidebar = action.payload;
      state.sidebar = sidebar;
    },
    sidebarActive: (state, action) => {
      const sidebarId = action.payload;
      state.sidebarActive = sidebarId;
    },
    changeActiveMenu: (state, action) => {
      const sidebarId = action.payload;
      const { sidebar } = state;
      const setActiveSidebar = sidebar.map((item, index) => {
        if (item.id === sidebarId) {
          item.isActive = true;
          item.isOpen = true;
        } else {
          item.isActive = false;
          item.isOpen = false;
        }
        return item;
      });
      state.sidebar = [...setActiveSidebar];
    },
    showPopupRule: (state) => {
      state.isShowPopupRule = true;
    },
    hidePopupRule: (state) => {
      state.isShowPopupRule = false;
    },
  },
});

const { reducer } = uiSlice;

export const selectUi = createSelector(
  (state) => ({
    isOpenPopUpAdvisory: state.ui.isOpenPopUpAdvisory,
    isGlobalLoading: state.ui.isGlobalLoading,
    sidebar: state.ui.sidebar,
    sidebarActive: state.ui.sidebarActive,
    isShowPopupRule: state.ui.isShowPopupRule,
  }),
  (state) => state,
);

export const {
  showGlobalLoading,
  hideGlobalLoading,
  setSideBar,
  sidebarActive,
  changeActiveMenu,
  hidePopupRule,
  showPopupRule,
} = uiSlice.actions;

export default reducer;
