export const groupInventory = () => {
  return [
    {
      name: 'Nhập kho',
      icon: '/images/order-icon/request-confirmation.svg',
      iconActive: '/images/order-icon/request-confirmation-active.svg',
      color: '#785BBC',
      childId: [7, 8],
      child: [],
    },
    {
      name: 'In và đóng gói',
      icon: '/images/order-icon/packed.svg',
      iconActive: '/images/order-icon/packed-active.svg',
      color: '#52A49A',
      childId: [9, 10, 11],
      child: [],
    },
    // {
    //   name: 'Vận chuyển',
    //   icon: '/images/order-icon/ordering.svg',
    //   iconActive: '/images/order-icon/ordering-active.svg',
    //   color: '#4CAABE',
    //   childId: [12, 13, 14],
    //   child: [],
    // },
    {
      name: 'Cần xử lý',
      icon: '/images/order-icon/order-request.svg',
      iconActive: '/images/order-icon/order-request-active.svg',
      color: '#4896EC',
      childId: [17, 19, 20, 18],
      child: [],
    },
  ];
};

export const getInventoryDetail = (type) => {
  if (!type) {
    return {};
  }
  const objectType = {
    export: {
      name: 'Xuất kho',
      type: ['export', 'manual_export'],
      urlBack: '/reports/inventory-receipt?type=export',
    },
    import: {
      name: 'Nhập kho',
      type: ['import', 'manual_import'],
      urlBack: '/reports/inventory-receipt?type=import',
    },
    manual_import: { name: 'Nhập kho', urlBack: '/reports/inventory-receipt?type=import' },
    manual_export: { name: 'Xuất kho', urlBack: '/reports/inventory-receipt?type=export' },
    inventory_adjust: {
      name: 'Bù trừ kiểm kho',
      type: ['inventory_adjust'],
      urlBack: '/reports/inventory-receipt?type=inventory_adjust',
    },
  };
  return objectType[type];
};
