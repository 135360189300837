import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllOrderStatus } from '@Apis/order';
import { getColorAndIconByStatusId, groupOrderStatus } from '@Utils/orderHelpers';
import { groupInventoryBuy } from '@Utils/inventoryBuyHelper';
import { groupInventory } from '@Utils/inventoryHelper';
import { groupShipping } from '@Utils/shippingHelper';
import axiosService from '@Lib/axiosService';

export const getAllStatus = createAsyncThunk('order/getAllStatus', async (params, thunkApi) => {
  try {
    const { data } = await getAllOrderStatus();
    return data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

const mappingOrder = (item, index) => {
  let active = 0;
  if (index === 0) {
    active = 1;
  }
  return { ...item, active };
};

const mappingOrderHasChild = (item, index, status) => {
  const child = item.childId.reduce((acc, current) => {
    const check = status.find((childItem) => childItem.id === current);
    if (check) {
      acc.push(check);
    }
    return acc;
  }, []);

  if (child.length > 0) {
    child[0].active = 1;
    item.child = child;
  }
  let active = 0;
  if (index === 0) {
    active = 1;
  }
  return { ...item, active };
};

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    status: [],
    groupStatus: [],
    hasChild: true,
    statusIdActive: 0,
  },
  reducers: {
    groupAllStatus: (state, action) => {
      const { type } = action.payload;
      const { status } = state;
      const objType = {
        order: {
          group: groupOrderStatus(),
          hasChild: true,
        },
        inventoryBuy: { group: groupInventoryBuy(), hasChild: false },
        inventory: { group: groupInventory(), hasChild: true },
        shipping: { group: groupShipping(), hasChild: false },
      };

      const { group, hasChild } = objType[type];

      const mappingGroup = group.map((item, index) => {
        let itemMapping;
        if (hasChild) {
          itemMapping = mappingOrderHasChild(item, index, status);
        } else {
          itemMapping = mappingOrder(item, index);
        }
        return itemMapping;
      });
      let statusIdActive = 0;
      if (hasChild) {
        if (mappingGroup[0].child.length > 0) {
          statusIdActive = mappingGroup[0].child[0].id;
        }
      } else {
        statusIdActive = mappingGroup[0].id;
      }
      state.statusIdActive = statusIdActive;
      state.hasChild = hasChild;
      state.groupStatus = mappingGroup;
    },
    setGroupAllStatus: (state, action) => {
      const data = action.payload;
      state.groupStatus = data;
    },
    setStatusActive: (state, action) => {
      const id = action.payload;
      const token = localStorage.getItem(process.env.TOKEN);
      axiosService.setHeader('Authorization', `Bearer ${token}`);
      state.statusIdActive = id;
    },
  },
  extraReducers: {
    [getAllStatus.fulfilled]: (state, action) => {
      let status = action.payload;
      const all = {
        id: 0,
        name: 'Tất cả',
      };
      status = [all, ...status];
      const statusMapped = status.map((item) => {
        const iconAndColor = getColorAndIconByStatusId(item.id);
        if (iconAndColor) {
          item = { ...item, ...iconAndColor };
        }
        return item;
      });
      state.status = statusMapped;
    },
  },
});

const { reducer } = orderSlice;

export const selectOrder = createSelector(
  (state) => ({
    order: state.order.status,
    groupStatus: state.order.groupStatus,
    defaultActive: state.order.defaultActive,
  }),
  (state) => state,
);

export const { groupAllStatus, setGroupAllStatus, setStatusActive } = orderSlice.actions;

export default reducer;
