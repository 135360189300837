import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { getCustomerLevels, getCustomerGroups } from '@Apis/customer';

export const customerLevels = createAsyncThunk('customer/customerLevels', async (thunkApi) => {
  try {
    const { data } = await getCustomerLevels();
    return data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

export const customerGroups = createAsyncThunk('customer/getCustomerGroups', async (thunkApi) => {
  try {
    const { data } = await getCustomerGroups();
    return data;
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    levels: [],
    groups: [],
  },
  reducers: {},
  extraReducers: {
    [customerLevels.fulfilled]: (state, action) => {
      const levels = action.payload;
      state.levels = levels;
    },
    [customerGroups.fulfilled]: (state, action) => {
      const groups = action.payload;
      state.groups = groups;
    },
  },
});

const { reducer } = customerSlice;

export const selectCustomer = createSelector(
  (state) => ({
    levels: state.customer.levels,
    groups: state.customer.groups,
  }),
  (state) => state,
);

export default reducer;
