import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import TextInput from '@Components/Input/TextInput';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { useMutation } from 'react-query';
import { changePassword } from '@Apis/user';

const ChangePasswordModal = ({ isOpen, toggle }) => {
  const user = useSelector((state) => state.user.info);
  const { mutate, isSuccess, error } = useMutation(changePassword, true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = (formData) => {
    if (formData.new_password !== formData.confirm_new_password) {
      return toastError('Mật khẩu mới không trùng khớp');
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Đổi mật khẩu thành công');
      toggle();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Đổi mật khẩu</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            labelName="Tên tài khoản"
            labelCol="12"
            inputCol="12"
            setValue={() => {}}
            defaultValue={user?.name}
            name="name"
            type="text"
            disabled
          />
          <TextInput
            labelName="Email đăng nhập"
            labelCol="12"
            inputCol="12"
            setValue={() => {}}
            defaultValue={user?.email}
            name="email"
            type="text"
            disabled
          />
          <TextInput
            labelName="Mật khẩu cũ"
            labelCol="12"
            inputCol="12"
            setValue={setValue}
            register={{ ...register('current_password', { required: true }) }}
            errors={errors}
            name="current_password"
            required
            type="text"
          />
          <TextInput
            labelName="Mật khẩu mới"
            labelCol="12"
            inputCol="12"
            setValue={setValue}
            register={{ ...register('new_password', { required: true }) }}
            errors={errors}
            required
            name="new_password"
            type="text"
          />
          <TextInput
            labelName="Xác nhận mật khẩu mới"
            labelCol="12"
            inputCol="12"
            setValue={setValue}
            register={{ ...register('confirm_new_password', { required: true }) }}
            errors={errors}
            required
            name="confirm_new_password"
            type="text"
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit(onSubmit)}>
          Xác nhận
        </Button>
        <Button color="seconday" onClick={toggle}>
          Huỷ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ChangePasswordModal;
