export const SOURCE_OPTIONS = [
  {
    label: 'Facebook',
    value: 'Facebook',
  },
  {
    label: 'Zalo',
    value: 'Zalo',
  },
  {
    label: 'Khác',
    value: 'Khác',
  },
];

export const CLOTHES_SIZE = [
  {
    label: 'XS',
    value: 'XS',
  },
  {
    label: 'S',
    value: 'S',
  },
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'L',
    value: 'L',
  },
  {
    label: 'XL',
    value: 'XL',
  },
  {
    label: 'XXL',
    value: 'XXL',
  },
];

export const SHOES_SIZE = [
  {
    label: 34,
    value: '34',
  },
  {
    label: 35,
    value: '35',
  },
  {
    label: 36,
    value: '36',
  },
  {
    label: 37,
    value: '37',
  },
  {
    label: 38,
    value: '38',
  },
  {
    label: 39,
    value: '39',
  },
  {
    label: 40,
    value: '40',
  },
  {
    label: 41,
    value: '41',
  },
  {
    label: 42,
    value: '42',
  },
  {
    label: 43,
    value: '43',
  },
  {
    label: 44,
    value: '44',
  },
  {
    label: 45,
    value: '45',
  },
  {
    label: 46,
    value: '46',
  },
];

export const PRODUCT_WAREHOUSE = [
  {
    label: 'Đặt hàng',
    value: 'dat_hang',
  },
  {
    label: 'Thiết kế',
    value: 'thiet_ke',
  },
  {
    label: 'Có sẵn',
    value: 'co_san',
  },
];

export const PRODUCT_CATEGORY = [
  {
    label: 'Quần áo nội địa',
    value: 'quan_ao',
  },
  {
    label: 'Giày dép nội địa',
    value: 'giay_dep',
  },
  {
    label: 'Túi xách nội địa',
    value: 'tui_xach',
  },
  {
    label: 'Phụ kiện nội địa',
    value: 'phu_kien',
  },
  {
    label: 'Quần áo thương hiệu',
    value: 'quan_ao_thuong_hieu',
  },
  {
    label: 'Giày dép thương hiệu',
    value: 'giay_dep_thuong_hieu',
  },
  {
    label: 'Túi xách thương hiệu',
    value: 'tui_xach_thuong_hieu',
  },
  {
    label: 'Phụ kiện thương hiệu',
    value: 'phu_kien_thuong_hieu',
  },
  {
    label: 'Thời trang thiết kế',
    value: 'thoi_trang_thiet_ke',
  },
];

export const GENDER = [
  {
    label: 'Nam',
    value: 'M',
  },
  {
    label: 'Nữ',
    value: 'F',
  },
];

export const SHIPPING_COMPANY = [
  {
    label: 'GHTK',
    value: 1,
    url: '/images/shipping/ghtk.svg',
    name: 'Giao Hàng Tiết Kiệm',
    nameInReceipt: 'Giaohangtietkiem.vn',
  },
  {
    label: 'GHN',
    value: 2,
    url: '/images/shipping/ghn.svg',
    name: 'Giao Hàng Nhanh',
    nameInReceipt: 'GHN.VN',
  },
  {
    label: 'TVC',
    value: 3,
    url: 'images/logo/logo-vuvu-02.png',
    name: 'Tự Vận Chuyển',
    nameInReceipt: 'TVC',
  },
];

export const SHIPPING_RECEIPT_TYPES = [
  { label: 'Gửi hãng vận chuyển đi', value: 'gui_hvc_di' },
  { label: 'Gửi hãng nội bộ', value: 'gui_hang_noi_bo' },
  { label: 'Gửi ship tự vận chuyển', value: 'gui_ship_tu_van_chuyen' },
];

export const INVENTORY_CHECK_RECEIPTS_TYPES = [{ label: 'Theo sản phẩm', value: 'by_products' }];
