import React from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Badge,
  Button,
} from 'reactstrap';
import { Bell } from 'react-feather';

function NotificationDropDown() {
  return (
    <UncontrolledDropdown
      tag="li"
      className="dropdown-notification flex-shrink nav-item mr-25 d-sm-flex d-none "
    >
      <DropdownToggle className="nav-link" onClick={(e) => e.preventDefault()} nav>
        <div className="d-sm-flex d-none align-items-center bell">
          <Bell size={21} color="#171725" />
          <Badge pill color="danger" className="badge-up">
            5
          </Badge>
        </div>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem className="d-flex" tag="div" header>
          <h4 className="notification-title mb-0 mr-auto">Notifications</h4>
          <Badge tag="div" color="light-primary" pill>
            5 New
          </Badge>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

NotificationDropDown.propTypes = {};

export default NotificationDropDown;
