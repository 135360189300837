import React, { Suspense } from 'react';
import { Switch, useHistory, Route, BrowserRouter as Router } from 'react-router-dom';
import Loader from '@Components/Loader';
import ErrorBoundary from '@Components/ErrorBoundary';
// import AuthenProvider from '@Components/AuthenWrapper';
import { useSelector } from 'react-redux';
import PageNotFound from '../views/PageNotFound';
import { privateConstant } from './private/contant';
import { publicConstant } from './public/contant';
import PrivateRoutes from './private/routes';
import PublicRoutes from './public/routes';

function Routes() {
  function renderPrivateRoutes() {
    let xhtml = null;
    const parentRouter = [];
    const childRouter = [];
    const routes = privateConstant;
    routes.map((router) => {
      if (router.child && router.child.length > 0) {
        const { accessName } = router;
        router.child.map((child) => {
          const item = { ...child, accessName };
          const { accessName: accessChild } = child;
          if (accessName) {
            item.accessChild = accessChild;
          }
          childRouter.push(item);
        });
      } else {
        parentRouter.push(router);
      }
      return '';
    });
    const routerNeedRender = [...parentRouter, ...childRouter];

    xhtml = routerNeedRender.map((route) => (
      <PrivateRoutes
        component={route.component}
        exact={route.exact}
        key={route.id}
        path={route.path}
        accessName={route.accessName}
        accessChild={route.accessChild}
      />
    ));
    return xhtml;
  }

  function renderPublicRoutes() {
    let xhtml = null;
    xhtml = publicConstant.map((route) => (
      <PublicRoutes
        component={route.component}
        exact={route.exact}
        key={route.id}
        path={route.path}
      />
    ));
    return xhtml;
  }

  const { pathname } = window.location;
  const token = localStorage.getItem(process.env.TOKEN);
  const history = useHistory();
  if (!token && pathname === '/') {
    history.push('login');
  }

  const checkIsPrivate = privateConstant.find((item) => item.path === pathname);
  if (!token && checkIsPrivate) {
    history.push('login');
  }

  if (token && pathname === '/') {
    history.push('/dashboard');
  }

  return (
    // <AuthenProvider>
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {renderPrivateRoutes()}
            {renderPublicRoutes()}
            <Route component={PageNotFound} path="*" />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
    // </AuthenProvider>
  );
}

export default Routes;
