import React, { useEffect, useRef, useState } from 'react';
import {
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { parsePositiveNumber, parseNumber, parsePrice, hidePhoneNumber } from '@Utils/helpers';
import { getInputErrorsObject } from './helper';

export default function TextInput({
  name,
  labelName,
  placeholder,
  register,
  errors,
  defaultValue,
  labelCol = '3',
  inputCol = '9',
  type = 'text',
  disabled = false,
  setValue = null,
  icon,
  rows,
  hidden = false,
  labelClassName,
  validationText,
  onKeyPress,
  onKeyDown,
  hidePhone,
}) {
  const ref = useRef();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState();
  useEffect(() => {
    if (setValue && defaultValue !== undefined) {
      let format = defaultValue;
      if (type === 'price') {
        format = parsePrice(parseInt(defaultValue).toString());
      }
      if (type === 'phone' && hidePhone) {
        format = hidePhoneNumber(defaultValue, !hidePhone);
      }
      setInputValue(format);
      setValue(name, defaultValue);
    } else {
      setInputValue('');
      setValue(name, '');
    }
  }, [defaultValue]);

  useEffect(() => {
    if (name && errors) {
      setError(getInputErrorsObject(name, errors));
    }
  }, [name, errors && Object.keys(errors)]);

  const handleChange = (value) => {
    if (type === 'phone') {
      const format = parsePositiveNumber(value);
      setInputValue(format);
      setValue(name, format);
    }
    if (type === 'text' || type === 'textarea' || type === 'email') {
      setInputValue(value);
      setValue(name, value);
    }
    if (type === 'quantity') {
      const format = parsePositiveNumber(value);
      setInputValue(format);
      setValue(name, parseInt(format));
    }
    if (type === 'number') {
      const format = parseNumber(value);
      setInputValue(format);
      setValue(name, parseFloat(format));
    }
    if (type === 'price') {
      const format = parsePrice(value);
      setInputValue(format);
      setValue(name, parseInt(format.replace(/,/g, '')));
    }
  };

  return (
    <FormGroup row>
      {labelName && (
        <Label md={labelCol} for={name} className={labelClassName ?? 'font-weight-bold'}>
          {labelName}
        </Label>
      )}
      <Col md={inputCol}>
        {icon ? (
          <InputGroup className="input-group-merge">
            <InputGroupAddon addonType="prepend">
              <InputGroupText className={disabled ? 'bg-disabled' : ''}>{icon}</InputGroupText>
            </InputGroupAddon>
            <Input
              className="form-control"
              type={type}
              name={name}
              {...register}
              placeholder={placeholder}
              value={inputValue}
              onChange={(e) => handleChange(e.target.value)}
              disabled={disabled}
              rows={rows}
              ref={ref}
              hidden={hidden}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
            />
          </InputGroup>
        ) : (
          <Input
            className="form-control"
            type={type}
            name={name}
            {...register}
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => handleChange(e.target.value)}
            disabled={disabled}
            rows={rows}
            ref={ref}
            hidden={hidden}
            onKeyPress={onKeyPress}
          />
        )}

        {error && (
          <p className="text-danger tx-12 mb-0" style={{ paddingTop: '2px' }}>
            {error.type === 'validate' ? validationText : error.message || 'Trường bắt buộc'}
          </p>
        )}
      </Col>
    </FormGroup>
  );
}

TextInput.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.object,
  errors: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  setValue: PropTypes.func,
  icon: PropTypes.object,
  rows: PropTypes.number,
  hidden: PropTypes.bool,
  labelClassName: PropTypes.string,
  validationText: PropTypes.string,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  hidePhone: PropTypes.bool,
};
