import { configureStore } from '@reduxjs/toolkit';
import uiReducer from '@Lib/slices/uiSlice';
import userReducer from '@Lib/slices/userSlice';
import orderReducer from '@Lib/slices/orderSlice';
import customerReducer from '@Lib/slices/customerSlice';
import devToolsEnhancer from 'remote-redux-devtools';

export default configureStore({
  reducer: {
    ui: uiReducer,
    user: userReducer,
    order: orderReducer,
    customer: customerReducer,
  },
  devTools: true,
  // enhancers: [devToolsEnhancer({ realtime: true, port: 8080 })],
});
