import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavbarBrand, NavLink, Navbar } from 'reactstrap';
import { X } from 'react-feather';
import { setSideBar, changeActiveMenu } from '@Lib/slices/uiSlice';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { privateConstant } from '../../../../routers/private/contant';
import MeunuItem from './MenuItem';
import MenuItemHasChild from './MenuItemHasChild';

function HorizontalMenu({ setMenuVisibility }) {
  const refMenu = useRef();
  const sidebar = useSelector((state) => state.ui.sidebar);
  const accessPermisions = useSelector((state) => state.user.accessPermisions);
  const sidebarActive = useSelector((state) => state.ui.sidebarActive);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (accessPermisions) {
      const sidebarConstant = privateConstant.reduce((acc, current) => {
        const { accessName } = current;
        if (!accessPermisions[accessName]) {
          return acc;
        }

        if (current.child) {
          current.child.map((itemChild) => {
            delete itemChild.component;
            return itemChild;
          });
        } else {
          delete current.component;
        }
        acc.push(current);
        return acc;
      }, []);
      dispatch(setSideBar(sidebarConstant));
    }
  }, [accessPermisions]);

  useEffect(() => {
    dispatch(changeActiveMenu(sidebarActive));
  }, [sidebarActive, accessPermisions]);

  const handleWheel = (event) => {
    if (refMenu) {
      refMenu.current.scrollLeft += event.deltaY;
    }
  };

  const handleClickItem = (item) => {
    const { path, query } = item;
    let url = path;
    if (query) {
      url = `${path}?${query}`;
    }
    history.push(url);
  };

  return (
    <div className="horizontal-menu-wrapper">
      <Navbar
        tag="div"
        expand="sm"
        className="header-navbar navbar-expand-sm navbar-horizontal navbar-light menu-border d-none d-sm-block floating-nav"
      >
        <div className="navbar-container main-menu-content" onWheel={handleWheel} ref={refMenu}>
          <ul className="nav navbar-nav flex-nowrap">
            {sidebar.length > 0 &&
              sidebar.map((route) => {
                if (route.child) {
                  return (
                    <MenuItemHasChild
                      item={route}
                      key={route.id}
                      handleClickItem={handleClickItem}
                    />
                  );
                }
                if (!route.hidden) {
                  return (
                    <MeunuItem item={route} key={route.id} handleClickItem={handleClickItem} />
                  );
                }
              })}
          </ul>
        </div>
      </Navbar>
      <div className="main-menu menu-fixed menu-accordion menu-shadow d-block d-md-none expanded menu-light">
        <div className="navbar-header expanded">
          <ul className="nav navbar-nav flex-row">
            <li className="nav-item mr-auto">
              <NavbarBrand>
                <img src="/images/logo/logo-vuvu-02.png" className="logo-vuvu" alt="logo" />
              </NavbarBrand>
            </li>
            <li className="nav-item nav-toggle pt-1">
              <NavLink onClick={setMenuVisibility}>
                <X color="#faa52d" />
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

HorizontalMenu.propTypes = {
  setMenuVisibility: PropTypes.func,
  sidebar: PropTypes.func,
};

export default HorizontalMenu;
