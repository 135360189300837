import React from 'react';
import PropsType from 'prop-types';

function PublicLayout(props) {
  const { children } = props;
  return <div> {children}</div>;
}
PublicLayout.propTypes = {
  children: PropsType.object,
};

export default PublicLayout;
