export const groupShipping = () => {
  return [
    {
      name: 'Chờ gom hàng',
      id: 12,
      icon: '/images/order-icon/order-request.svg',
      iconActive: '/images/order-icon/order-request-active.svg',
      color: '#EB5757',
      childId: [],
      child: [],
    },
    {
      name: 'Đang chuyển',
      id: 13,
      icon: '/images/order-icon/order-request.svg',
      iconActive: '/images/order-icon/order-request-active.svg',
      color: '#EB5757',
      childId: [],
      child: [],
    },
    {
      id: 14,
      name: 'Thành công',
      icon: '/images/order-icon/order-request.svg',
      iconActive: '/images/order-icon/order-request-active.svg',
      color: '#EB5757',
      childId: [],
      child: [],
    },
  ];
};
